<template>
  <div>
    <aw-tabler :table="tableJSON">
      <template #filter>
        <el-form :model="tableJSON.filter" label-suffix="：" inline>
          <el-form-item label="名称" prop="name">
            <el-input v-model="tableJSON.filter.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="上下架" prop="is_push">
            <el-select
              v-model="tableJSON.filter.is_push"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value="" />
              <el-option label="上架" :value="1" />
              <el-option label="下架" :value="-1" />
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #drawercontent>
        <el-form
          class="form"
          style="padding-right: 10px"
          ref="goodsFormEle"
          :model="goodsForm"
          :rules="goodsRules"
          label-width="100px"
          v-loading="drawerLoading"
        >
          <h3>商品信息</h3>
          <el-form-item label="名称" prop="name">
            <el-input v-model="goodsForm.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="图片" prop="imgs">
            <div class="upload">
              <div class="upload-img">
                <template v-for="(img, i) in goodsForm.imgs" :key="i">
                  <div class="img-box">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="img"
                    />
                    <div class="modal">
                      <div class="icons">
                        <el-icon class="icon" @click="showViewer = true"><ZoomIn /></el-icon>
                        <el-icon class="icon" @click="deleteImages(i)"><Delete /></el-icon>
                      </div>
                    </div>
                  </div>
                </template>
                <aw-upload
                  v-if="goodsForm.imgs.length < 5"
                  :mode="uploadMode"
                  @action="uploadAction"
                >
                  <template #button>
                    <div class="upload-btn">
                      <el-icon><Plus /></el-icon>
                      <span class="text">上传</span>
                    </div>
                  </template>
                </aw-upload>
              </div>
              <p class="tips-text">建议尺寸：最大上传5张，建议200*200px像素，png、jpg、jpeg格式</p>
            </div>
          </el-form-item>
          <el-form-item label="商品详情" prop="content">
            <RichEditor
              :value="goodsForm.content"
              @geteditordatas="(val) => goodsForm.content = val"
            />
          </el-form-item>
          <h3>销售信息</h3>
          <el-form-item label="限购" prop="is_limit">
            <div class="restrict">
              <el-checkbox
                v-model="goodsForm.is_limit"
                label="每人限购"
                :true-label="1"
                :false-label="-1"
              />
              <div v-if="goodsForm.is_limit === 1" class="restrict-content">
                <el-select v-model="goodsForm.limit_type" class="w-100" placeholder="请选择">
                  <el-option label="终身" :value="5" />
                  <el-option label="每年" :value="4" />
                  <el-option label="每月" :value="3" />
                  <el-option label="每周" :value="2" />
                  <el-option label="每天" :value="1" />
                </el-select>
                <span class="m-lr">限购</span>
                <el-input-number
                  v-model="goodsForm.limit_num"
                  class="w-100"
                  :min="1"
                  controls-position="right"
                  placeholder="请输入"
                />
                <el-popover :teleported="false" width="460" trigger="hover">
                  <template #reference>
                    <span class="m-lr t-primary">限购周期说明</span>
                  </template>
                  <template #default>
                    <div class="restrict-text">
                      <h5>限购周期说明</h5>
                      <p>终身限购时限为商品存在的整个时段</p>
                      <p>每年限购周期为每年第一天的00：00：00至每年最后一天的23:59:59</p>
                      <p>每月限购周期为每月第一天的00：00：00至每月最后一天的23:59:59</p>
                      <p>每周限购周期为周一00：00：00至周天23:59:59</p>
                      <p>每天限购周期为当天00：00：00至当天23:59:59</p>
                    </div>
                  </template>
                </el-popover>
              </div>
            </div>
          </el-form-item>
          <h3>其他信息</h3>
          <el-form-item label="排序" prop="sort">
            <el-input-number
              v-model="goodsForm.sort"
              class="w-100"
              controls-position="right"
              placeholder="请输入"
            />
            <span class="m-lr">数字越大越靠前</span>
          </el-form-item>
          <el-form-item label="上下架" prop="is_push">
            <el-radio-group v-model="goodsForm.is_push">
              <el-radio :label="1">上架</el-radio>
              <el-radio :label="-1">下架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="btns">
            <el-button
              class="btn"
              type="primary"
              :loading="submitLoading"
              @click="handlerStore"
            >保存</el-button>
            <el-button class="btn" @click="cancelDrawer">返回</el-button>
          </el-form-item>
        </el-form>
      </template>
    </aw-tabler>
    
    <el-image-viewer
      v-if="showViewer"
      :url-list="goodsForm.imgs"
      @close="showViewer = false"
    />
  </div>
</template>

<script setup>
import {ref, reactive} from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {http, tool} from "@/assets/js"
import {useRoute} from 'vue-router'
import {ElMessage} from 'element-plus'
import RichEditor from '@/components/tinymceEditor/richEditor.vue'

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const uploadMode = ref({
  uploadinfo: {
    httpRequest: true,
    limit: 5,
    rules: {
      images: {
        image_width: 0, //图片宽度，0表示不限宽
        image_height: 0, //图片高度，0表示不限高
        size: 1024*5, //文件大小（单位kb）
        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'], //文件格式
        suffix: ['jpeg', 'jpg', 'gif', 'bmp', 'png'], //文件后缀-用于判断
        typechart: 'png/bmp/gif/jpg/jpeg' //文件格式提示规则
      }
    }
  }
})
const uploadAction = (e) => {
  if(e.actionData.res.status === 200){
    goodsForm.value.imgs.push(e.actionData.url)
  }
}

const goodsFormEle = ref()
const goodsForm = ref({
  name: '', // 名称
  imgs: [], // 图片
  content: '', // 富文本详情
  is_limit: -1, // 是否限购
  limit_type: 5, // 限购类型
  limit_num: null, // 限购数量
  sort: 0, // 排序
  is_push: 1, // 上下架 1上架 -1下架
})
const goodsRules = ref({
  name: [
    { required: true, message: '请输入名称' }
  ],
  thumbs: [
    { required: true, message: '请上传图片' }
  ],
  sort: [
    {required: true, message: '请输入序号'}
  ]
})

const drawerLoading = ref(false)
const getGoodsDetail = (id) => {
  drawerLoading.value = true
  http.post('/admin/goods/view', {id: id}).then(res => {
    
    // html标签反转译
    let content = res.content
    content = content.replace(/&lt;/g, "<");
    content = content.replace(/&gt;/g, ">");
    content = content.replace(/&quot;/g, '"');
    content = content.replace(/&amp;nbsp;/g, ' ');
    content = content.replace(/&amp;ldquo;/g, '"');
    content = content.replace(/&amp;rdquo;/g, '"');
    
    goodsForm.value = {
      ...res,
      content: content,
      imgs: res.imgs ? res.imgs.split(',') : []
    }
  }).finally(() => {
    drawerLoading.value = false
  })
}

const tableJSON = ref({
  title: '商品管理',
  loading: false,
  filter: {
    name: '',
    is_push: ''
  },
  datas: [],
  columns: [
      ...tableColumn([
        {
          title: 'ID',
          key: 'id',
          width: '100px',
        },
        {
          title: '名称',
          key: 'name',
          minWidth: '180px'
        },
        {
          title: '图片',
          key: 'imgs',
          width: '140px',
          image: true
        },
        {
          title: '上下架',
          key: 'is_push',
          width: '70px',
          confirm: true,
          props: { disabled: !powerlimits.includes('spgl_qy') },
          switch: (data) => {
            http.post('/admin/goods/switch', {id: data.id})
          }
        },
        {
          title: '排序',
          key: 'sort',
          width: '100px'
        },
      ]),
    {
      fixed: 'right',
      title: '操作',
      key: '',
      width: '100px',
      buttons: [
        {
          name: '编辑',
          props: { type: 'primary' },
          show: () => powerlimits.includes('spgl_bj'),
          action: (data) => {
            tableJSON.value.drawer.title = '修改商品'
            tableJSON.value.drawer.show = true
            getGoodsDetail(data.id)
          }
        }
      ]
    },
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    tableJSON.value.loading = true
    if(powerlimits.includes('spgl_lb')) {
      let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
      let result = await http.post('/admin/goods/index', { ...params, ...route.query, ...page })
      tableJSON.value.datas = result.data
      tableJSON.value.page.total = result.total || Infinity
      tableJSON.value.page.page = result.current_page
      tableJSON.value.page.page_size = result.per_page
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
    tableJSON.value.loading = false
    return
  },
  extras: [
    {
      name: '添加',
      props: { type: 'primary', plain: true, },
      show: () => powerlimits.includes('spgl_tj'),
      action: () => {
        tableJSON.value.drawer.show = true
        tableJSON.value.drawer.title = '添加商品'
      }
    }
  ],
  drawer: {
    show: false,
    title: '',
    size: '70%',
    close: () => {
      cancelDrawer()
    }
  }
})

const cancelDrawer = () => {
  goodsFormEle.value.resetFields()
  delete goodsForm.value.id
  goodsForm.value.imgs = []
  uploadMode.value.fileList = []
  tableJSON.value.drawer.show = false
}

// 添加/修改
const submitLoading = ref(false)
const handlerStore = () => {
  goodsFormEle.value.validate((valid) => {
    if(valid) {
      submitLoading.value = true
      const formData = JSON.parse(JSON.stringify(goodsForm.value))
      formData.imgs = formData.imgs.join(',')
      http.post(goodsForm.value.id ? '/admin/goods/edit' : '/admin/goods/add', {
        ...formData
      }).then(res => {
        if(typeof res === 'boolean' && res) {
          ElMessage.success(goodsForm.value.id ? '修改成功' : '添加成功')
          cancelDrawer()
          tableJSON.value.page.page = 1
          tableJSON.value.action({})
        }
      }).finally(() => {
        submitLoading.value = false
      })
    }
  })
}


// 图片
const deleteImages = (index) => {
  goodsForm.value.imgs.splice(index, 1)
}
const showViewer = ref(false)
</script>

<style lang="scss" scoped>
.m-lr{
  margin: 0 5px;
}
.t-primary{
  color: var(--el-color-primary);
}
.w-100{
  width: 140px;
}
.tips-text{
  font-size: 12px;
  color: #666;
}
.upload-img{
  display: flex;
  flex-direction: row;
  .img-box{
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    margin-right: 10px;
    .modal{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: rgba(0,0,0,.8);
      color: #fff;
      opacity: 0;
      .icon{
        cursor: pointer;
        padding: 0 5px;
      }
    }
    &:hover .modal{
      opacity: 1;
    }
  }
  .upload-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background-color: #f8f9f8f9;
    border: 1px dashed var(--el-border-color-base);
    box-sizing: border-box;
    color: #666;
    .text{
      line-height: 24px;
      font-size: 12px;
    }
    &:hover{
      color: var(--el-color-primary);
      border-color: var(--el-color-primary);
    }
  }
}
</style>
